import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

export function useDeliveryDateAdjustment() {
  const experiments = useExperiments()

  return function deliveryDateAdjustment(deliveryDateStr: string | Date) {
    const deliveryDate =
      typeof deliveryDateStr === 'string'
        ? new Date(deliveryDateStr)
        : deliveryDateStr

    if (experiments['experiment.deliveryDateAdjustment'] === 'withAdjustment') {
      const adjustedDate = new Date(deliveryDate)

      adjustedDate.setDate(adjustedDate.getDate() - 1)

      const isWeekendDay =
        adjustedDate.getDay() === 0 || adjustedDate.getDay() === 6
      const isToday = adjustedDate.toDateString() === new Date().toDateString()
      const isPast = adjustedDate < new Date()

      if (!(isWeekendDay || isToday || isPast)) {
        return adjustedDate
      }
    }

    return deliveryDate
  }
}
